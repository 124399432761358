import PHBG1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { NumericFormat } from 'react-number-format';

const LoanCalculator = () => {

    const [loanAmt, setloanAmt] = useState(0)
    const [loanMonth, setloanMonth] = useState(0)
    const [interestRate, setinterestRate] = useState(10.15)
    const [emiVal, setEmival] = useState()
    const [TotalVal, setTotalVal] = useState()
    const [slider1, setSlider1] = useState(10000)
    const [slider2, setSlider2] = useState(1)
    const [monthlyPay, setMonthlypay] = useState(1)
    
    const INITIAL_DATA = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        loan: "",
        total: "",
        term: "",
        intrest: "",
        monthlypay:""
    }

    const [form, setForm] = useState({ ...INITIAL_DATA })

    function changeHandler(evt) {
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
    }

    useEffect(() => {
        if (slider1 > 0 && slider2 > 0 && interestRate > 0.01) {
            emiCalculate()
        }
    }, [slider1, slider2, interestRate])

    // const emiCalculate = () => {
    // const interestRate = setinterestRate;
    //     const interestRatePercent = parseFloat(interestRate) / 100;
    //      const totalPay = slider1 * interestRatePercent + parseInt(slider1, 10);
    //      const monthlyPay = totalPay / parseInt(slider2,10);

    //      setEmival(monthlyPay)
    //      setTotalVal(totalPay)
    //  }

    const emiCalculate = () => {
        const principal = parseInt(slider1, 10);
        const interestRatePercent = parseFloat(interestRate) / 100;
        const durationInMonths = parseInt(slider2, 10);

        // Formula to calculate compounded interest:
        // A = P * (1 + r/n)^(nt)
        // where:
        // A = final amount
        // P = principal
        // r = annual interest rate as a decimal
        // n = number of times the interest is compounded per year
        // t = number of years
        const compoundedInterest = principal * Math.pow(1 + (interestRatePercent / 12), durationInMonths);

        // EMI = P * r * (1 + r)^n / ((1 + r)^n – 1)
        // where:
        // EMI = monthly payment
        // P = principal
        // r = monthly interest rate as a decimal
        // n = number of payments
        const monthlyPay = (principal * interestRatePercent * Math.pow(1 + interestRatePercent, durationInMonths)) / (Math.pow(1 + interestRatePercent, durationInMonths) - 1);

        setEmival(monthlyPay);
        setTotalVal(compoundedInterest);
    };

    const handleSlider1Change = (e) => {
        setSlider1(e.target.value);
    };

    const handleSlider1Input = (e) => {
        setSlider1(e.target.value)
    }

    //  action="assets/inc/sendemail.php"

    const sendEmail = () => {
        setForm({
            ...form,
            intrest: interestRate,
            loan: slider1,
            term: slider2,
            total: TotalVal,
            monthlypay: emiVal

        });

       var formdata = new FormData();
       formdata.append("data", JSON.stringify({
        ...form,
        intrest: interestRate,
        loan: slider1,
        term: slider2,
        total: TotalVal,
        monthlypay: emiVal
    }));
       var requestOptions = {
         method: 'POST',
         body: formdata,
         redirect: 'follow'
       };
       
       fetch("https://thefinancecompany.co.za/assets/inc/sendemail.php", requestOptions)
         .then(response => response.text())
         .then(result => {
            console.log(result)
            alert(result);
         })
         .catch(error => console.log('error', error));

    }

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>

            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${PHBG1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li>/</li>
                        <li><span>Loan Calculator</span></li>
                    </ul>
                    <h2>Loan Calculator</h2>
                </div>
            </section>

            <section className="contact-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-one__form">
                                <div className="row low-gutters">
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Your Name" name="name" onChange={changeHandler} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" pattern=".*@.*\..*" placeholder="Your Email" name="email" onChange={changeHandler} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Phone Number" name="phone" onChange={changeHandler} />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Subject" name="subject" onChange={changeHandler} />
                                    </div>

                                    <div className="col-md-6">
                                        <b>Your loan ammount: R<i id="loan-amm"><NumericFormat value={slider1} displayType={'text'} thousandSeparator={true} decimalScale={2} name="loan" onChange={changeHandler} /></i></b>
                                    </div>

                                    <div className="col-md-6">
                                        <b>Total with intrest: R<i id="loan-total"><NumericFormat value={TotalVal} displayType={'text'} thousandSeparator={true} decimalScale={2} name="total" onChange={changeHandler} /></i></b>
                                    </div>

                                    <div className="col-md-6">
                                        <b>Term of use: <i id="loan-month" name="term">{slider2}</i> Months</b>
                                    </div>

                                    <div className="col-md-6">
                                        <b>Intrest rate: <i id="intrest-rate"><NumericFormat value={interestRate} displayType={'text'} name="intrest" onChange={changeHandler} /></i>%</b>
                                    </div>

                                    <div className="col-md-6">
                                        <b>Montly Payback: <i id="montly-pay"><NumericFormat value={emiVal} displayType={'text'} thousandSeparator={true} decimalScale={2} name="monthlypay" onChange={changeHandler} /></i></b>
                                    </div>


                                    <div className="col-md-12">
                                        <textarea name="message" placeholder="Write Message" onChange={changeHandler} ></textarea>
                                        <button onClick={sendEmail} className="thm-btn" type="submit">Send A Message</button>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form action="#" id="loan-calculator" data-interest-rate="15" className="about-one__form wow fadeInRight" data-wow-duration="1500ms">
                                <h3>How Much You Need</h3>
                                <div className="about-one__form-content">
                                    <div className="input-box__top">
                                        <span>R10000</span>
                                        <span>R50000000</span>
                                    </div>

                                    <div className="input-box">
                                        <div className="range-slider-count" id="range-slider-count">
                                            <input type="range" className="w-100" min="10000" max="50000000" value={slider1} onChange={handleSlider1Change} />
                                        </div>
                                        <input type="text" value={slider1} onChange={handleSlider1Input} />
                                        <input type="hidden" value="" id="min-value-rangeslider-count" />
                                        <input type="hidden" value="" id="max-value-rangeslider-count" />
                                    </div>

                                    <div className="input-box__top">
                                        <span>1 Month</span>
                                        <span>48 Months</span>
                                    </div>
                                    <div className="input-box">
                                        <div className="range-slider-month" id="range-slider-month">
                                            <input
                                                type="range"
                                                className="w-100"
                                                min="1"
                                                max="48"
                                                value={slider2}
                                                onChange={(e) => setSlider2(e.target.value)} 
                                                />
                                        </div>
                                        <span>{slider2}</span>
                                        <input type="hidden" value="" id="min-value-rangeslider-month" />
                                        <input type="hidden" value="" id="max-value-rangeslider-month" />
                                    </div>
                                    <div className="input-box__top">
                                        <span>Interest Rate</span>
                                        <input type="number" step="0.01" min="1" value={interestRate} 
                                                onChange={(e) => {
                                                setinterestRate(e.target.value)
                                        }} />
                                    </div>
                                    <p>
                                        <span>Pay Monthly</span>
                                        <b>R<i id="loan-monthly-pay" displayType={'text'} ><NumericFormat value={emiVal} displayType={'text'} thousandSeparator={true} decimalScale={2} /></i></b>
                                    </p>
                                    <p>
                                        <span>Term of Use</span>
                                        <b><i id="loan-month" >{slider2} 
                                       
                                        </i> Months</b>
                                    </p>
                                    <p>
                                        <span>Total Pay Back</span>
                                        <b>R<i id="loan-total"><NumericFormat value={TotalVal} displayType={'text'} thousandSeparator={true} decimalScale={2} /></i></b>
                                    </p>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoanCalculator