import React, { useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter as Router,  useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom"

import Navbar from '../components/Navbar/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import Home2 from '../components/Home2/Main'
import Team from '../components/About/Team/Main'
import TeamDetails from '../components/About/TeamDetails/Main'
import Service from '../components/Services/Service/Main'
import ServiceDetails from '../components/Services/ServiceDetails/Main'
import NewsMain from '../components/News/NewsMain/Main'
import NewsDetails from '../components/News/NewsDetails/Main'
import Work from '../components/Pages/Work/Main'
import LoanCalculator from '../components/Pages/LoanCalculator/Main'
import CreditCard from '../components/Credit/CreditCard/Main'
import CreditCardDetails from '../components/Credit/CreditCardDetails/Main'
import FAQ from '../components/Pages/FAQ/Main'
import Testimonials from '../components/Pages/Testimonial/Main'
import Contact from '../components/Contact/Main'
import StructuredFinancing from '../components/Services/ServiceDetails/StructuredFinancing'
import BusinessLoan from '../components/Services/ServiceDetails/BusinessLoan'


const Routing = () => {
   
    
    
    
    return (
        
        <>
        
            <Navbar />

            
            <Routes  >
                <Route path="/" element={<Home />} />
                <Route path="/home-02" element={<Home2 />} />
                <Route path="/team" element={<Team />} />
                <Route path="/team-details" element={<TeamDetails />} />
                <Route exact path="/service" element={<Service />} />
                
                <Route path="/news-main" element={<NewsMain />} />
                <Route path="/news-details" element={<NewsDetails />} />
                <Route path="/work" element={<Work />} />
                <Route exact path="/loan-calculator" element={< LoanCalculator />} />
                <Route path="/credit-card" element={< CreditCard />} />
                <Route path="/credit-card-details" element={< CreditCardDetails />} />
                <Route exact path="/faqs" element={<FAQ />} />
                <Route path="/testimonial" element={<Testimonials />} />
                <Route exact path="/contact" element={<Contact />} />

                <Route exact path="/service-details/*" element={<ServiceDetails  />} >
                    <Route  path="StructuredFinancing" element={<StructuredFinancing   />} />
                    <Route  path="BusinessLoan" element={<BusinessLoan   />} />



                </Route>

            </Routes>
            

            <Footer />

        </>

        
    )
}




export default Routing