

import TrustBG1 from '../../assets/images/shapes/trust-bg-1-1.png'
import FunFactBG1 from '../../assets/images/backgrounds/funfact-bg-1-1.jpg'
import ActionBG1 from '../../assets/images/backgrounds/call-to-action-bg-1-1.jpg'
import AnimatedNumber from "animated-number-react"
import { Navigation, Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link }  from 'react-router-dom'
import React ,{ useState , useEffect } from 'react'


import BGMS1 from '../../assets/images/main-slider/main-slider-2-1.jpg'
import BGMS2 from '../../assets/images/main-slider/main-slider-2-2.jpg'
import BGMS3 from '../../assets/images/main-slider/main-slider-1-1.jpg'
import BGMS4 from '../../assets/images/main-slider/main-slider-1-2.jpg'


import VBG1 from '../../assets/images/resources/video-1-1.jpg'
import TEAMBG1 from  '../../assets/images/backgrounds/team-bg-1-1.jpg'


import {useLocation }  from 'react-router-dom'

import WOW from 'wowjs'


const Main = () => {
    const [loanAmt, setloanAmt] = useState(0)
    const [loanMonth, setloanMonth] = useState(0)
    const [interestRate, setinterestRate] = useState(0)
    const [emiVal, setEmival] = useState()
    const [TotalVal, setTotalVal] = useState()
    const [slider1, setSlider1] = useState(1000)
    const [slider2, setSlider2] = useState(1)







    const [mobile, setmobile] = useState(false)
    const [ytShow, setytShow] = useState(false)
    const [sticky, setSticky] = useState(false);
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [aboutDrop, setaboutDrop] = useState(false)
    const [servicesDrop, setservicesDrop] = useState(false)
    const [newsDrop, setnewsDrop] = useState(false)
    const [pagesDrop, setpagesDrop] = useState(false)
    const [cardDrop, setcardDrop] = useState(false)
    const [search, setsearch] = useState(false)
    const[activeFaq, setActiveFaq] = useState({b: true})
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})



    useEffect(() => {
        if (slider1 > 0 && slider2 > 0) {
            emiCalculate()
        }
    }, [slider1, slider2])

    const emiCalculate = () => {
        const interestRate = 15;
        const interestRatePercent = parseInt(interestRate, 10) / 100;
        const totalPay = slider1 * interestRatePercent + parseInt(slider1, 10);
        const monthlyPay = totalPay / parseInt(slider2,10);
        
        setEmival(monthlyPay)
        setTotalVal(totalPay)

    }

  return (
    <>
    
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>

        <section  className="main-slider main-slider__two">
            <Swiper  className="swiper-container thm-swiper__slider" 
                modules={[Navigation, Autoplay]}
                slidesPerView={1}
                loop={true}
                navigation={{
                    nextEl: "#main-slider__swiper-button-next",
                    prevEl: "#main-slider__swiper-button-prev"
                }}
                autoplay={{delay:5000}}
                >
                <div  className="swiper-wrapper">
                    <SwiperSlide>
                    <div  className="swiper-slide">
                        <div  className="image-layer" style={{ backgroundImage: `url(${BGMS1})`}}>
                        </div>
                        <div  className="container">
                            <div  className="row">
                                <div  className="col-xl-8 col-lg-8">
                                    <p>Welcome to The Finance Company</p>
                                    <h2>Building A Brigther Financial Future for your Company and staff</h2>
                                    <div  className="button-wrap">
                                        <Link to="/loan-calculator"  className=" thm-btn">Apply For Financing</Link>
                                        <Link to="/contact"  className=" thm-btn">Contact Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div  className="swiper-slide">
                        <div  className="image-layer" style={{ backgroundImage: `url(${BGMS2})`}}>
                        </div>
                        <div  className="container">
                            <div  className="row">
                                <div  className="col-xl-8 col-lg-8">
                                    <p>Welcome to The Finance Company</p>
                                    <h2>Empowering growth through tailored financing solutions</h2>
                                    <div  className="button-wrap">
                                        <Link to="/loan-calculator"  className=" thm-btn">Apply For Financing</Link>
                                        <Link to="/contact"  className=" thm-btn">Contact Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div  className="swiper-slide">
                        <div  className="image-layer" style={{ backgroundImage: `url(${BGMS1})`}}>
                        </div>
                        <div  className="container">
                            <div  className="row">
                                <div  className="col-xl-8 col-lg-8">
                                    <p>Simple & Secure Financing Process</p>
                                    <h2>Connecting
                                        All Your Financing
                                        Needs without effort</h2>
                                    <div  className="button-wrap">
                                        <Link to="/loan-calculator"  className=" thm-btn">Apply For Loan</Link>
                                        <Link to="/contact"  className=" thm-btn">Contact Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </SwiperSlide>
                </div>
                <div  className="main-slider__nav">
                    <div  className="swiper-button-prev" id="main-slider__swiper-button-next"><i  className="pylon-icon-left-arrow"></i>
                    </div>
                    <div  className="swiper-button-next" id="main-slider__swiper-button-prev"><i  className="pylon-icon-right-arrow"></i>
                    </div>
                </div>
            </Swiper>
        </section>

        <section  className="service-two">
            <img src="assets/images/shapes/service-shape-1-1.png"  className="service-two__shape-1" alt="" />
            <img src="assets/images/shapes/service-shape-1-2.png"  className="service-two__shape-2" alt="" />
            <img src="assets/images/shapes/service-shape-1-3.png"  className="service-two__shape-3" alt="" />
            <div  className="container">
                <div  className="row">
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
                        <div  className="service-two__card">
                            <i  className="pylon-icon-consumer-behavior"></i>
                            <h3><Link to="/service-details">Tailored Financial Solutions</Link></h3>
                            <p>Find the perfect financial solution for your business with our range of services, from commercial property finance to structured finance, we have you covered.</p>
                            <Link to="/service-details"  className="service-two__card-link"><i  className="pylon-icon-right-arrow"></i>Read More</Link>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
                        <div  className="service-two__card">
                            <i  className="pylon-icon-point-of-sale"></i>
                            <h3><Link to="/service-details">Boost Your Business Growth</Link></h3>
                            <p>Maximize your business potential with our flexible lending options and cash flow solutions, including corporate lending and invoice discounting.</p>
                            <Link to="/service-details"  className="service-two__card-link"><i  className="pylon-icon-right-arrow"></i>Read More</Link>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
                        <div  className="service-two__card">
                            <i  className="pylon-icon-strategy"></i>
                            <h3><Link to="/service-details">Expertise in Corporate Finance</Link></h3>
                            <p>Leverage our extensive experience in mergers and acquisitions, business finance and specialized property finance to take your company to the next level.</p>
                            <Link to="/service-details"  className="service-two__card-link"><i  className="pylon-icon-right-arrow"></i>Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section  className="about-two">
            <img src="assets/images/shapes/about-shape-1-1.png"  className="about-two__shape-1" alt="" />
            <img src="assets/images/shapes/about-shape-1-2.png"  className="about-two__shape-2" alt="" />
            <img src="assets/images/shapes/about-shape-1-3.png"  className="about-two__shape-3" alt="" />
            <div  className="container">
                <div  className="row">
                    <div  className="col-lg-6 wow fadeInLeft" data-wow-duration="1500ms">
                        <div  className="about-two__image">
                            <img src="assets/images/resources/about-2-1.png" alt="" />
                            <div  className="about-two__box">
                                <i  className="pylon-icon-assets"></i>
                                <h3>26 Years
                                    of Expericence</h3>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-6">
                        <div  className="about-two__content">
                            <div  className="block-title text-left">
                                <p>Company Introductions</p>
                                <h2>Specialised and Capital Equipment Finance at a glance</h2>
                            </div>
                            <p>Businesses that need to buy expensive equipment often find that their cash flow takes a knock and growth becomes an impossible task. The Finance Company understands just how important this is for business growth, and that’s why our Specialised and Capital Equipment Finance works to enhance the working capital cycle and allows businesses the freedom to use their existing capital to maximise growth</p>
                            <ul  className="list-unstyled about-two__list">
                                <li>
                                    <i  className="fa fa-check-circle"></i>
                                    We’ve less paper work
                                </li>
                                <li>
                                    <i  className="fa fa-check-circle"></i>
                                    We offer any type of financing
                                </li>
                                <li>
                                    <i  className="fa fa-check-circle"></i>
                                    We’ve the best process fees
                                </li>
                                <li>
                                    <i  className="fa fa-check-circle"></i>
                                    We process quickly
                                </li>
                            </ul>
                            <div  className="button-wrap">
                                <Link to="/service"  className="thm-btn">Discover More</Link>
                                <div  className="main-header__info-phone">
                                    <i  className="pylon-icon-tech-support"></i>
                                    <div  className="main-header__info-phone-content">
                                        <span>Call Anytime</span>
                                        <h3><Link to="tel:0825208683">082 520 8683</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section  className="call-to-action" style={{ backgroundImage: `url(${ActionBG1})`}}>
            <div  className="container">
                <div  className="left-content">
                    <p><span>Simple</span><span>Transparent</span><span>Secure</span></p>
                    <h3>Get Business Financing Quickly</h3>
                </div>
                <div  className="right-content">
                    <Link to="/loan-calculator"  className="thm-btn">Apply For Financing</Link>
                </div>
            </div>
        </section>

        <section  className="service-one service-one__home-two">
            <img src="assets/images/shapes/service-shape-2-1.png" alt=""  className="service-one__home-two__shape-1" />
            <img src="assets/images/shapes/service-shape-2-2.png" alt=""  className="service-one__home-two__shape-2" />
            <img src="assets/images/shapes/service-shape-2-3.png" alt=""  className="service-one__home-two__shape-3" />
            <div  className="container">
                <div  className="block-title text-center">
                    <p>Some of What We’re Offering</p>
                    <h2>Financing Services</h2>
                </div>
                <div  className="row">
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-1.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Structured Financing</Link></h3>
                                <p>100% Finance on property transactions on resedential, commercial and industrial</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-2.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Corporate lending</Link></h3>
                                <p>Term loans, overdrafts, equity funding of bee initiatives</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-3.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Invoice discounting</Link></h3>
                                <p>Designed to accelerate the working capital of a business.</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div  className="block-title text-center">
                    <p>&nbsp;</p>
                    
                </div>
                <div  className="row">
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-4.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Specialized Property Finance</Link></h3>
                                <p>Designed to finance special purpose-built building. i.e., Cold storage buildings, cement manufacturing & Mines</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-5.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Commercial Property Finance</Link></h3>
                                <p>Financing of commercial property at 75% to 85% of value. Traditionally property bought as investments or owner-occupied property</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-6.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Commercial Property Re-Finance</Link></h3>
                                <p>We can re-finance business premises to 75% to 85% of value to give the business a cashflow injection for future growth by acessing the Equity in the property</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>

        

        

        
    
    
    </>
  )
}

export default Main