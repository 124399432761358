import { Link}  from 'react-router-dom'
import React from 'react'

const Footer = () => {
  return (
    <>
        <footer  className="site-footer">
            <div  className="container">
                <div  className="row">
                    <div  className="col-lg-3 col-md-6">
                        <div  className="footer-widget footer-widget__about">
                            < Link to="/">
                                <img src="assets/images/logo-light.png" width="155" alt=""/>
                            </Link>
                            <p>Empowering growth through tailored financing solutions.</p>
                            <div  className="footer-widget__about-phone">
                                <i  className="pylon-icon-tech-support"></i>
                                <div  className="footer-widget__about-phone-content">
                                    <span>Call Anytime</span>
                                    <h3>< Link to="tel:0825208683">082 520 8683</Link></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-2 col-md-6">
                        <div  className="footer-widget footer-widget__link">
                            <h3  className="footer-widget__title">Explore</h3>
                            <ul  className="list-unstyled footer-widget__link-list">
                                
                                <li>< Link to="/service">Our Services</Link></li>
                                <li>< Link to="/loan-calculator">Loan Calculator</Link></li>
                                
                                <li>< Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div  className="col-lg-3 col-md-6">
                        <div  className="footer-widget footer-widget__post">
                          
                          
                        </div>
                    </div>
                    <div  className="col-lg-4 col-md-6">
                        <div  className="footer-widget footer-widget__contact">
                            <h3>Contact</h3>
                            <ul  className="list-unstyled footer-widget__contact-list">
                                <li>
                                    < Link to="/contact"><i  className="pylon-icon-email1"></i>etienne@thefinancecompany.co.za</Link>
                                </li>
                                <li>
                                    < Link to="/contact"><i  className="pylon-icon-clock2"></i>Mon - Fri 9:00 AM - 5:00 PM</Link>
                                </li>
                                <li>
                                    < Link to="/contact"><i  className="pylon-icon-pin1"></i>HP&D Park Pond Road, Boksburg</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div  className="bottom-footer">
            <div  className="container">
                <p>© Copyright 2023 by thefinancecompany.co.za</p>
                <div  className="bottom-footer__social">
                    < Link to="#"  className="fab fa-facebook-square"></Link>
                    < Link to="#"  className="fab fa-twitter"></Link>
                    < Link to="#"  className="fab fa-pinterest-p"></Link>
                    < Link to="#"  className="fab fa-instagram"></Link>
                </div>
            </div>
        </div>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
    
    </>
  )
}

export default Footer