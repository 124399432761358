import PHBG1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link}  from 'react-router-dom'
import React from 'react'

const Service = () => {
  return (
    <>

        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"  style={{ backgroundImage: `url(${PHBG1})`}}></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li>/</li>
                    <li><span>Services</span></li>
                </ul>
                <h2>Services</h2>
            </div>
        </section>

        <section className="service-page pt-120 pb-60">
            <div className="container">
                <div  className="row">
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-1.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Structured Financing</Link></h3>
                                <p>100% Finance on property transactions on resedential, commercial and industrial</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-2.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Corporate lending</Link></h3>
                                <p>Term loans, overdrafts, equity funding of bee initiatives</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-3.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Invoice discounting</Link></h3>
                                <p>Designed to accelerate the working capital of a business.</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div  className="block-title text-center">
                    <p>&nbsp;</p>
                    
                </div>
                <div  className="row">
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-1.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Specialized Property Finance</Link></h3>
                                <p>Designed to finance special purpose-built building. i.e., Cold storage buildings, cement manufacturing & Mines</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-2.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Commercial Property Finance</Link></h3>
                                <p>Financing of commercial property at 75% to 85% of value. Traditionally property bought as investments or owner-occupied property</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div  className="service-one__card">
                            <div  className="service-one__image">
                                <img src="assets/images/services/services-1-3.png" alt="" />
                            </div>
                            <div  className="service-one__content">
                                <h3><Link to="/service-details">Commercial Property Re-Finance</Link></h3>
                                <p>We can re-finance business premises to 75% to 85% of value to give the business a cashflow injection for future growth by acessing the Equity in the property</p>
                                <Link to="/service-details"  className="pylon-icon-right-arrow service-one__link"></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Service