import PHBG1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link}  from 'react-router-dom'
import React, { useState, useEffect } from 'react'



const Contact = () => {

    const INITIAL_DATA = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        loan: "",
        total: "",
        term: "",
        intrest: "",
        monthlypay:""
    }

    const [form, setForm] = useState({ ...INITIAL_DATA })

    function changeHandler(evt) {
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
    }

    const sendEmail = () => {
        setForm({
            ...form
            

        });

   
    var formdata = new FormData();
       formdata.append("data", JSON.stringify({
        ...form
      
    }));

       
       var requestOptions = {
         method: 'POST',
         body: formdata,
         redirect: 'follow'
       };
       
       fetch("https://thefinancecompany.co.za/assets/inc/sendemail.php", requestOptions)
         .then(response => response.text())
         .then(result => {
            console.log(result)
            alert(result);
         })
         .catch(error => console.log('error', error));

          }

    

  return (
    <>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>

        <section className="page-header">
            <div className="page-header__bg" style={{ backgroundImage: `url(${PHBG1})`}}></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li>/</li>
                    <li><span>Contact</span></li>
                </ul>
                <h2>Contact</h2>
            </div>
        </section>
        <section className="contact-one">
            <div className="container">
                <div className="block-title text-center">
                    <p>Contact With Us</p>
                    <h2>Leave a Message</h2>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <div className="contact-one__content">
                            <p>There are many variations of but the majority have suffered alteration in some form.</p>
                            <div className="contact-one__box">
                                <i className="pylon-icon-telephone"></i>
                                <div className="contact-one__box-content">
                                    <h4>Call Anytime</h4>
                                    <Link to="#">082 520 8683</Link>
                                </div>
                            </div>
                            <div className="contact-one__box">
                                <i className="pylon-icon-email1"></i>
                                <div className="contact-one__box-content">
                                    <h4>Write Email</h4>
                                    <Link to="#">etienne@thefinancecompany.co.za</Link>
                                </div>
                            </div>
                            <div className="contact-one__box">
                                <i className="pylon-icon-pin1"></i>
                                <div className="contact-one__box-content">
                                    <h4>Visit Office</h4>
                                    <Link to="#">HP&D Park Pond Road, Boksburg</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="contact-one__form">
                            <div className="row low-gutters">
                                <div className="col-md-6">
                                        <input type="text" placeholder="Your Name" name="name" onChange={changeHandler} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" pattern=".*@.*\..*" placeholder="Your Email" name="email" onChange={changeHandler} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Phone Number" name="phone" onChange={changeHandler} />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Subject" name="subject" onChange={changeHandler} />
                                    </div>

                                <div className="col-md-12">
                                        <textarea name="message" placeholder="Write Message" onChange={changeHandler} ></textarea>
                                        <button onClick={sendEmail} className="thm-btn" type="submit">Send A Message</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="google-map__home-two">
            
            <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28647.9711545336!2d28.11200387910156!3d-26.16424689999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9519ffcab97915%3A0xcfd09eafc6c000a2!2sThe%20Finance%20Co!5e0!3m2!1sen!2sza!4v1676899236515!5m2!1sen!2sza" className="map__home-two" allowFullscreen ></iframe>
        </div>
    </>
  )
}

export default Contact