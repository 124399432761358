import PHBG1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import SSBAR1 from '../../../assets/images/services/service-sidebar-1-1.jpg'



import React, { useEffect, useState } from 'react'
import { Link, Outlet } from "react-router-dom"





const ServiceDetails = () => {
    const[activeFaq, setActiveFaq] = useState({b: true})

  return (

    <>

        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg" style={{ backgroundImage: `url(${PHBG1})`}}></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li>/</li>
                    <li><span>Services Details</span></li>
                </ul>
                <h2>Services Details</h2>
            </div>
        </section>

        <section className="service-details faq-one">
            <div className="container">
                <div className="row">
                    
                        <div className="col-lg-4">

                            <div className="service-sidebar">
                                <div className="service-sidebar__category">
                                    <h3>All Categories</h3>
                                    
                                    
                                    <ul className="list-unstyled service-sidebar__category-list">
                                    
                                        <li><Link to="/service-details/StructuredFinancing">Structured Financing</Link></li>
                                        
                                       
                                    </ul>
                                    
                                </div>
                                <div className="service-sidebar__call">
                                    <div className="service-sidebar__call-bg" style={{ backgroundImage: `url(${SSBAR1})`}}></div>
                                    <i className="pylon-icon-tech-support"></i>
                                    <h3><Link to="/contact">082 520 8683</Link></h3>
                                    <p>If you have any questions or requests please do not hesitate to get professional advice</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <Outlet />
                        
                        </div>
                            
                    
                </div>
            </div>
        </section>
    </>
  )
}

export default ServiceDetails


