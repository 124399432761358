import PHBG1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import ActionBG1 from '../../../assets/images/backgrounds/call-to-action-bg-1-1.jpg'
import { Link}  from 'react-router-dom'
import React from 'react'

const CreditCard = () => {
  return (
    <>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>

        <section className="page-header">
            <div className="page-header__bg" style={{ backgroundImage: `url(${PHBG1})`}}></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li>/</li>
                    <li><span>Credit Card</span></li>
                </ul>
                <h2>Credit Card</h2>
            </div>
        </section>

        <section className="credit-card">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="credit-card__box">
                            <div className="credit-card__box-image">
                                <img src="assets/images/credit-card/credit-card-1-1.png" alt="" />
                            </div>
                            <div className="credit-card__content">
                                <h3><Link to="/credit-card-details">Visa Platinum Card</Link></h3>
                                <p>There are many variations of passages of lorem ipsum available the majority have some.</p>
                                <Link to="/credit-card-details" className="credit-card__link"><i className="pylon-icon-right-arrow"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="credit-card__box">
                            <div className="credit-card__box-image">
                                <img src="assets/images/credit-card/credit-card-1-2.png" alt="" />
                            </div>
                            <div className="credit-card__content">
                                <h3><Link to="/credit-card-details">Visa Gold Card</Link></h3>
                                <p>There are many variations of passages of lorem ipsum available the majority have some.</p>
                                <Link to="/credit-card-details" className="credit-card__link"><i className="pylon-icon-right-arrow"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="credit-card__box">
                            <div className="credit-card__box-image">
                                <img src="assets/images/credit-card/credit-card-1-3.png" alt="" />
                            </div>
                            <div className="credit-card__content">
                                <h3><Link to="/credit-card-details">Visa classNameic Card</Link></h3>
                                <p>There are many variations of passages of lorem ipsum available the majority have some.</p>
                                <Link to="/credit-card-details" className="credit-card__link"><i className="pylon-icon-right-arrow"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="credit-card__box">
                            <div className="credit-card__box-image">
                                <img src="assets/images/credit-card/credit-card-1-4.png" alt="" />
                            </div>
                            <div className="credit-card__content">
                                <h3><Link to="/credit-card-details">Titanium Mastercard</Link></h3>
                                <p>There are many variations of passages of lorem ipsum available the majority have some.</p>
                                <Link to="/credit-card-details" className="credit-card__link"><i className="pylon-icon-right-arrow"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="credit-card__box">
                            <div className="credit-card__box-image">
                                <img src="assets/images/credit-card/credit-card-1-5.png" alt="" />
                            </div>
                            <div className="credit-card__content">
                                <h3><Link to="/credit-card-details">Titanium Mastercard</Link></h3>
                                <p>There are many variations of passages of lorem ipsum available the majority have some.</p>
                                <Link to="/credit-card-details" className="credit-card__link"><i className="pylon-icon-right-arrow"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="credit-card__box">
                            <div className="credit-card__box-image">
                                <img src="assets/images/credit-card/credit-card-1-6.png" alt="" />
                            </div>
                            <div className="credit-card__content">
                                <h3><Link to="/credit-card-details">Express Card</Link></h3>
                                <p>There are many variations of passages of lorem ipsum available the majority have some.</p>
                                <Link to="/credit-card-details" className="credit-card__link"><i className="pylon-icon-right-arrow"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="call-to-action"  style={{ backgroundImage: `url(${ActionBG1})`}}>
            <div className="container">
                <div className="left-content">
                    <p><span>Simple</span><span>Transparent</span><span>Secure</span></p>
                    <h3>Get a Business Loans Quickly</h3>
                </div>
                <div className="right-content">
                    <Link to="#" className="thm-btn">Apply For Loan</Link>
                </div>
            </div>
        </section>
    </>
  )
}

export default CreditCard